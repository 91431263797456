<template>
    <div class="modal-cropper" :class="{ pc: options.pc }">
        <div class="body">
            <div class="cropper">
                <img id="cropping-image" :src="imgAsDataUrl" />
            </div>
        </div>
        <div class="buttons">
            <div class="flex-row flex-row flex-between">
                <button @click="$emit('close')" class="btn" v-html="$translate('CANCEL')" />
                <button @click="confirm" class="btn" v-html="$translate('CONFIRM')" />
            </div>
        </div>
    </div>
</template>

<script>
import Cropper from 'cropperjs'

export default {
    name: 'ModalCropper',
    props: ['options'],
    data: () => ({
        imgAsDataUrl: null,
        cropper: null,
    }),
    mounted() {
        this.init()
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: {
                top: '#000000',
                bottom: '$grey-09',
            },
        })
    },
    beforeDestroy() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: '#FFFFFF',
        })
    },
    methods: {
        init() {
            if (!this.options || !this.options.imgFile) return

            const reader = new FileReader()
            reader.onload = e => {
                this.imgAsDataUrl = e.target.result
                setTimeout(_ => this.initCropper(), 10)
            }
            reader.readAsDataURL(this.options.imgFile)
        },
        initCropper() {
            const image = document.getElementById('cropping-image')
            if (!image) return

            const options = {
                initialAspectRatio: 1,
                autoCropArea: 1,
                toggleDragModeOnDblclick: false,
                dragMode: 'none',
                background: false,
                maxContainerWidth: window.innerWidth,
                viewMode: 2,
            }

            if (!this.options.allowFreeAspectRatio) options.aspectRatio = 1

            this.cropper = new Cropper(image, options)
        },
        confirm() {
            const blobToFile = blob => new File([blob], this.options.imgFile.name, { type: 'image/jpeg' })

            this.cropper.getCroppedCanvas().toBlob(blob => this.$emit('close', blobToFile(blob)))
        },
    },
}
</script>
<style scoped lang="scss">
.modal-cropper {
    &.pc {
        width: 20vw;
        height: 20vw;
    }
}
</style>
